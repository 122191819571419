
import React, { useState, useEffect } from 'react';
import TableOfContents from './TableOfContents'
import AnnotationWidget from './AnnotationWidget'
import NoteWidget from './NoteWidget'
import BookmarkBar from './BookmarkBar'
import NotesBar from './NotesBar'
import Poll from './Poll'
import NotificationPopUp from './NotificationPopUp'
import { getRawAndExtractedEbook, getRawAndExtractedMiniLesson, getRawAndExtractedBellRinger, getTranslations} from '../services/contentful/repository'
import { logActivity, saveUserAnswer, saveUserResponse } from '../services/tracking/sendActivity'
import { saveAnnotation, getAnnotations, destroyAnnotation } from '../services/annotation/annotations'
import { recordVideoClicked, recordAudioClicked } from '../services/tracking/userActions'
import { getAllQuizAnswers } from '../services/questions/ebookQuiz'
import { createPoll } from '../services/questions/polls'
import { accountStyle } from '../accountStyling'

const Ereader = (props) => {
  const localStorageKey = `postPossible.ereader.${props.contentful_id}.chapter`;
  const [ebook, setEbook] = useState('');
  const [ebookTitle, setEbookTitle] = useState('')
  const [chapter, setChapter] = useState(-1);
  const [chapterId, setChapterId] = useState(-1);
  const [toc, setToc] = useState([]);
  const [quizUserAnswers, setQuizUserAnswers] = useState({mcAnswers: [], saAnswers: []});
  const [quizQuestionAnswers, setQuizQuestionAnswers] = useState([]);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [activeHighlight, setActiveHighlight] = useState('');
  const [range, setRange] = useState({})
  const [selection, setSelection] = useState()
  const [annotationId, setAnnotationId] = useState(null)
  const [bookmarks, setBookmarks] = useState([])
  const [notes, setNotes] = useState([])
  const [locale, setLocale] = useState(localStorage.getItem(`postPossible.ereader.locale`))
  const [translations, setTranslations] = useState([])
  const [displayTranslations, setDisplayTranslations] = useState(null);
  const [pollId, setPollId] = useState(null);
  const [displayFontPicker, setDisplayFontPicker] = useState(null);
  const [fontSize, setFontSize] = useState(20);
  const [correctAnswers, setCorrectAnswers] = useState([])
  const [tempShortAnswers, setTempShortAnswers] = useState({})

  const upperFontLimit = 30
  const lowerFontLimit = 13



  window.onkeydown= function(gfg){
    if(gfg.keyCode === 39){
      incrementChapter()
    };
    if(gfg.keyCode === 37){
      decrementChapter()
    };
    $('#notificationPopUp').css({
      display: 'none'
    });
  };

  useEffect(() => {
    //Add mark.js scripts
    const script = document.createElement("script")
    script.src ="https://cdn.jsdelivr.net/npm/mark.js@8.11.1/dist/mark.min.js"
    script.async = true
    script.crossOrigin = "anonymous"
    document.body.appendChild(script)

    if (!locale) {
      if (props.defaultLanguage) {
        setLocale(props.defaultLanguage)
      } else {
        setLocale('en-US')
      }
    }

    let tempEbook
    if (props.miniLesson) {
      tempEbook = getRawAndExtractedMiniLesson(props.contentful_id, props.ebookStyle, locale)
    } else if (props.bellRinger) {
      tempEbook = getRawAndExtractedBellRinger(props.contentful_id, props.ebookStyle, locale)
    } else {
      tempEbook = getRawAndExtractedEbook(props.contentful_id, props.ebookStyle, locale)
    }
    // TODO add fonts to account?
    $('head').append(`
    <link href='https://fonts.googleapis.com/css?family=Zilla Slab' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Lora' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>

    <style>
      @charset "UTF-8";
      ${accountStyle(props.accountStyle, props.ebookStyle)}
    </style>
    `);

    tempEbook.then((ebook) =>{
      let rawEbook = ebook[0]
      setEbookTitle(rawEbook.items[0].fields.title)
      let extractedEbook = ebook[1]
      console.log("successfully loaded ebook")
      setEbook(extractedEbook)
      
      if (!props.miniLesson && !props.bellRinger) {
        //gets chapterUUID from url search query
        let params = new URLSearchParams(document.location.search);
        let storedChapter
        if (!!params.get('chapterUUID')) {
          storedChapter = extractedEbook.units.findIndex((e) => params.get('chapterUUID') === e.id)
        } else {
          //check local storage for stored chapter number
          storedChapter = localStorage.getItem(`postPossible.ereader.${props.contentful_id}.chapter`)
        }

        if (!storedChapter || storedChapter === -1) {
          storedChapter = 0
        } else {
          storedChapter = parseInt(storedChapter)
        }

        //update url with current chapterUUID
        let uuid = extractedEbook.units[storedChapter].id
        const url = new URL(window.location)
        url.searchParams.set('chapterUUID', uuid)
        window.history.pushState(null, '', url.toString())
        
        //get current_user's answers to ebook's quiz questions
        fetch(`${window.location.origin}/ereader/quiz_user_answers/${props.contentful_id}`)
          .then(response => response.json())
          .then(obj => {
            setQuizQuestionAnswers(getAllQuizAnswers(rawEbook))
            setQuizUserAnswers(obj.data)
            logActivity('bookOpened', props.contentful_id);
            setChapter(storedChapter)
          })
      } else if (props.miniLesson) {
        logActivity('bookOpened', props.contentful_id, null, null, null, null, null, 'miniLesson');
        fetch(`${window.location.origin}/ereader/other_user_answers/${props.contentful_id}`)
          .then(response => response.json())
          .then(obj => {
            setQuizQuestionAnswers(getAllQuizAnswers(rawEbook))
            setQuizUserAnswers(obj.data)
            setChapter('miniLesson')
          })
        
      } else if (props.bellRinger) {
        logActivity('bookOpened', props.contentful_id, null, null, null, null, null, 'bellRinger');
        fetch(`${window.location.origin}/ereader/other_user_answers/${props.contentful_id}`)
          .then(response => response.json())
          .then(obj => {
            setQuizQuestionAnswers(getAllQuizAnswers(rawEbook))
            setQuizUserAnswers(obj.data)
            setChapter('bellRinger')
          })
      }

    }).catch((err) => {
      console.log("failed to load ebook" + err)
    })

    if (!props.miniLesson && !props.bellRinger) {
      getBookmarks(props.contentful_id)
      let tempTranslations = getTranslations(props.contentful_id, 'book')

      tempTranslations.then((foundTranslations) => {
        setTranslations(foundTranslations)
      })
    }

    return() => {
      // TODO closing book event
      // logActivity('bookClosed', props.contentful_id)
    }

  }, [""]);

  useEffect(() => {
    if ((chapter >= 0 && ebook && ebook.units && chapter < ebook.units.length) || chapter === 'miniLesson' || chapter === 'bellRinger'){
      if (!(chapter  === 'miniLesson' || chapter === 'bellRinger')){
        setChapterId(ebook.units[chapter].id)
      }
      loadFrame()
    } else if (chapter < 0) {
      setChapter(0)
    } else {
      setChapter(ebook && ebook.units && ebook.units.length - 1)
    }
  }, [chapter])

  useEffect(()=>{
    if (!props.miniLesson && !props.bellRinger) {
      makeTableOfContents()
    }
  }, [ebook])

  useEffect(()=>{
    console.log('Changing the language')
    let storedLocale = localStorage.getItem(`postPossible.ereader.locale`)
    if (storedLocale !== locale) {
      localStorage.setItem(`postPossible.ereader.locale`, locale)

      window.location.reload()
    }
  }, [locale])

  function loadFrame(){
    let data
    if (props.miniLesson || props.bellRinger) {
      data = ebook
    } else {
      if (props.isStudent) {
        data = ebook.units[chapter].body
      } else {
        data = ebook.units[chapter].body + ebook.units[chapter].teacherBlock
      }
    }
    const $frame = $('#myFrame');
    const $frameBody = $('#frameBody')
    const $ereader = $('#ereader')
    const $head = $('head')
    // debugger
    // const userQuestionAnswers = quizUserAnswers;
    $frame.ready(() => {

        $ereader.removeClass('loading');
        $frameBody.html(data);
        $frameBody.attr('dir', 'auto');
  
        data.replace('<p><img src="');
        // force iframe to scroll to top
        $frameBody.scrollTop(0);
  
        $frameBody.contents().find('a').attr('target', '_blank');
  
        const url = 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
        $head.append(
            $('<link/>', {
                rel: 'stylesheet',
                href: url,
                type: 'text/css'
            })
        );
        $head.append(
            $('<script/>', {
                href: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.0.0/jquery.min.js',
                type: 'text/javascript'
            })
        );
        $head.append(`
            <style>
                body > p {
                white-space: pre-wrap;
                }
            </style>`
        );
  
        // maps all of the correct answers
        let correctAnswersMap = {};
        for (let i = 0; i < quizQuestionAnswers.length; i++) {
            for (let j = 0; j < quizQuestionAnswers[i].quizAnswers.length; j++) {
                if (quizQuestionAnswers[i].quizAnswers[j].isCorrect) {
                    correctAnswersMap[quizQuestionAnswers[i].quizUUID] = {
                        id: quizQuestionAnswers[i].quizAnswers[j].quizAnswerUUID,
                        text: quizQuestionAnswers[i].quizAnswers[j].quizAnswer
                    };
                }
            }
        }

        setCorrectAnswers(correctAnswersMap)
        
        let answeredQuestions = [];

        if (!props.isStudent) {
          const allMcQuestions = document.querySelectorAll('.ebook-multiple-choice-quiz');
  
          allMcQuestions.forEach(question => {
            if (question.classList.contains('poll-question')) {
              var button = $(`<input type="button" value="Start Poll" class="startPollButton btn btn-primary share-with-students" data-quizuuid="${question.dataset.quizuuid}" />`);
              $(question).append(button)
              
              $(button).on('click', () => initiatePoll(question.dataset.quizuuid))
            }
          });
        }

        // marks up questions that have been answered
        quizUserAnswers.mcAnswers.forEach((a) => {
          let html = '';
          const questionContainer = $(`div[data-quizuuid="${a.quizUUID}"]`);
          if (props.isStudent) {
            $(questionContainer).find('ul').remove();
            $(questionContainer).find('button').remove();
            // prevents duplicate answers from being added to markup
            if (answeredQuestions.indexOf(a.quizUUID) == -1) {
              if (correctAnswersMap[a.quizUUID]) {
                if (a.correct) {
                    html = `<p class="reimagine-quiz-answer"><i class="fa fa-check" aria-hidden="true"></i>You answered correctly: ${
                        correctAnswersMap[a.quizUUID].text
                    }</p>`;
                } else {
                    html = `<p class="reimagine-quiz-answer"><i class="fa fa-times" aria-hidden="true"></i> The correct answer is: ${
                        correctAnswersMap[a.quizUUID].text
                    }</p>`;
                }
              } else {
                html =  `<p class="reimagine-quiz-answer"><i class="fa fa-check" aria-hidden="true"></i> You answered: ${
                  user_answer
                }</p>`;
              }
              questionContainer.append(html);
              answeredQuestions.push(a.quizUUID);
            }
          }
        });

        // Sets the temp stored value for Short Answers, if user has responded already this session.
        // This value will be overwritten by the database Answer if it exists.
        if (tempShortAnswers) {
          for (const [key, value] of Object.entries(tempShortAnswers)) {
            const questionContainer = $(`div[data-quizuuid="${key}"]`);
            $(questionContainer).find('textarea').val(value.studentResponse);
          }
        }
        
        // marks up questions that have been answered
        quizUserAnswers.saAnswers.forEach((a) => {
          let studentScore = a.studentScore >= 0 ? a.studentScore : '--';
          const questionContainer = $(`div[data-quizuuid="${a.quizUUID}"]`);
          $(questionContainer)
            .find('.sa-quiz-points > .student-score')
            .text(studentScore);
          $(questionContainer)
                .find('textarea')
                .attr('placeholder', a.studentResponse);
            $(questionContainer).find('textarea').attr('disabled', true);
            $(questionContainer).find('button').remove();
            if (a.teacherResponse) {
              $(questionContainer).append(
                  `<p style="font-size:1.5rem"><strong>Teacher response:</strong> ${a.teacherResponse}</p>`
              );
            }
            // prevents dupliate answers from being added to markup
            if (answeredQuestions.indexOf(a.quizUUID) == -1) {
                answeredQuestions.push(a.quizUUID);
            }
        });
      
        // add in annotations
        getAnnotations(props.contentful_id, chapter);
  
        let unitId

        if (chapter == 'bellRinger') {
          unitId = 'bellRinger'
        } else if (chapter == 'miniLesson') {
          unitId = 'miniLesson'
        } else {
          unitId = ebook.units[chapter].id
        }

        // // record clicked video
        recordVideoClicked(
            chapter, 
            props.contentful_id,
            unitId
        );
  
        // // record clicked audio
        recordAudioClicked(
            chapter,
            props.contentful_id,
            unitId
        );
  
        //Check for notifications
        $(".postpossible-notification-tag").each(function(i, notification) {
          // TODO send notification to rails api
          fetch(`${window.location.origin}/ereader/add_notification`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              notification_id: notification.id,
              ebook_contentful_id: props.contentful_id,
              ebook_id: props.postpossibleId,
              goal: $(notification).attr('data-goal') === 'undefined' ? null : $(notification).attr('data-goal'),
              delay: $(notification).attr('data-delay') === 'undefined' ? null : $(notification).attr('data-delay'),
              message: $(notification).attr('data-message') === 'undefined' ? null : $(notification).attr('data-message'),
              title: $(notification).attr('data-title') === 'undefined' ? null : $(notification).attr('data-title'),
              emailTemplate: $(notification).attr('data-email-template') === 'undefined' ? null : $(notification).attr('data-email-template'),
              popUp: $(notification).hasClass('popUpNotification') ,
              email: $(notification).hasClass('emailNotification') ,
              textMessage: $(notification).hasClass('textNotification')

            })
          }).then(response => response.json())
          .then(obj => {
            
            if(obj.display) {
              console.log("display notification")
              // If new notification && class 'popUpNotification'
              if ($(notification).hasClass('popUpNotification')) {
                $('#notificationPopUp').css({
                  display: 'block'
                });
                if ($(notification).attr('data-title') !== 'undefined') {
                  $("#notification-modal-title").text($(notification).attr('data-title'));
                } else {
                  $("#notification-modal-title").text("Notification");
                }
                $("#notification-text").text($(notification).attr('data-message'));
              }
            } else {
              console.log("notification not displayed")
            }
          })
      
        });

        // Something with the previous button?
        let previous = $($frame).contents().find('.popup-open').prev();
        if (previous) {
            previous.css('display', 'inline-block');
            previous.css('white-space', 'normal');
            if (previous.html()) {
                previous.html(previous.html().replace('\n', ''));
            }
        }

        const headerDiv = document.querySelector('.poptential-header__chapter');
        if (headerDiv && ebookTitle) {
          headerDiv.innerHTML = ebookTitle;
        }

        // This tracks User Short Answers (that are not locked) throughout page navigation.
        const textareas = document.querySelectorAll('textarea');
        textareas.forEach(textarea => {
          textarea.oninput = function() {
            trackUserShortAnswer(textarea.parentElement.attributes['data-quizuuid'].value, textarea.value);
          }
        });
    });
  }

  // Tracks a users Short Answer response between page navigation, before they Submit a finalized response.
  function trackUserShortAnswer(quizUUID, textInput) {
    let tempAnswersMap = tempShortAnswers;
    tempAnswersMap[quizUUID] = {
      studentResponse: textInput
    };
    setTempShortAnswers(tempAnswersMap);
  }

  function submitEbookQuizAnswer(target) {
    let quizUUID;
    if (target.parentElement) {
      if (target.parentElement.attributes['data-quizuuid']) {
        quizUUID = target.parentElement.attributes['data-quizuuid'].value;
      } else {
        quizUUID =
          target.parentElement.parentElement.parentElement.attributes[
            'data-quizuuid'
          ].value;
      }
    }

    target.style.display = 'none';
  
    let userAnswer = '';
    let userExplanation = '';
    let answerCorrect = false;

    userAnswer = $(target).parent().find('input').attr('data-quiz-choiceuuid');
      
    const allQuizzes = quizQuestionAnswers;
    // const chapterIndex = ebook.findIndex((e) => chapterUUID === e.chapterUUID);
    const quiz = allQuizzes.find((ele) => ele.quizUUID === quizUUID);
    let correctAnswer = null
    if (quiz) {
      correctAnswer = quiz.quizAnswers.find((e) => e.isCorrect);
    }

    if (quizUUID) {
      displayMCAnswer(quizUUID, userAnswer, correctAnswer)
      if (chapter == 'miniLesson' || chapter == 'bellRinger') {
        saveUserAnswer(userAnswer, quizUUID, answerCorrect, props.contentful_id, chapter);
      } else {
        saveUserAnswer(userAnswer, quizUUID, answerCorrect, props.contentful_id, ebook.units[chapter].id);
      }
    }
      
  }

  // Used after User submits an Answer to Short Answer survey.
  function submitEbookSurveyAnswer(target) {
    const quizUUID = target.parentElement.attributes['data-quizuuid'].value;
    let userAnswer = $(target).parent().find("#shortAnswerAnswer").val();

    // If user didn't provide an answer, but clicked Submit, ignore the click.
    if (userAnswer == '') {
      return;
    }

    // This hides the Submit button, and turns textarea not editable
    target.style.display = 'none';
    $(target).parent().find('textarea').attr('disabled', true);
    
    let chapterToSave = (chapter === 'bellRinger' || chapter === 'miniLesson') ? chapter : ebook.units[chapter].id
    saveUserResponse(userAnswer, quizUUID, props.contentful_id, chapterToSave)
    
    let tempEbook = ebook;

    if (quizUUID) {
      let html = `<p class="reimagine-quiz-answer"><i class="fa fa-check" aria-hidden="true"></i>Thank you for your feedback!</p>`;
      $(target).parent().append(html);
  
      // remember this html in state for back/next
      if (chapter > -1) {
        const body = $("#frameBody").html(); //TODO: this better fix
        tempEbook.units[chapter].data = body;

        setEbook(tempEbook)
      }

      // Update the user answer mapping to include new answer
      let newSAanswer = {
        quizUUID: quizUUID,
        studentResponse: userAnswer,
        studentScore: null,
        teacherResponse: null
      }
      quizUserAnswers.saAnswers.push(newSAanswer);
    }
  }

  // Used after User submits an Answer to Multiple Choice question.
  function displayMCAnswer(quizUUID, answer_id, correctAnswer) {
    let html = '';
    const questionContainer = $(`div[data-quizuuid="${quizUUID}"]`);
    if ($(`input[data-quiz-choiceuuid="${answer_id}"]`)[0]) {
      const user_answer = $(`input[data-quiz-choiceuuid="${answer_id}"]`)[0].value
      $(questionContainer).find('ul').remove();
      $(questionContainer).find('button').remove();

      if (correctAnswer) {
        if (answer_id == correctAnswer.quizAnswerUUID) {
            html = `<p class="reimagine-quiz-answer"><i class="fa fa-check" aria-hidden="true"></i>You answered correctly: ${
                correctAnswers[quizUUID].text
            }</p>`;
        } else {
            html = `<p class="reimagine-quiz-answer"><i class="fa fa-times" aria-hidden="true"></i> The correct answer is: ${
                correctAnswers[quizUUID].text
            }</p>`;
        }
      } else {
        html =  `<p class="reimagine-quiz-answer"><i class="fa fa-check" aria-hidden="true"></i> You answered: ${
          user_answer
        }</p>`;
      }
      questionContainer.append(html);

      // Update the user answer mapping to include new answer
      let newMCanswer = {
        answer_id: answer_id,
        correct: answer_id == correctAnswer.quizAnswerUUID,
        quizUUID: quizUUID
      }
      quizUserAnswers.mcAnswers.push(newMCanswer);
    }
  }
  
  function decrementChapter() {
    if (chapter > 0) {
      changePage(chapter - 1, true)
    }
  }

  function incrementChapter() {
    if (ebook.units && chapter < ebook.units.length) {
      changePage(chapter + 1, true)
    }
  }

  function moreChapters() {
    return (ebook.units && (chapter < ebook.units.length-1))
  }

  function makeTableOfContents() {
    if (ebook === '') {
      return
    }
    let newTOC = []
    
    ebook.units.forEach( unit => {
      if (!unit.parentChapterNumber) {
        newTOC.push({name: unit.title, id: unit.id, href: "#", children: []})
      } else {
        if (unit.parentChapterNumber === 1) {
          newTOC[newTOC.length - 1].children.push({name: unit.title, id: unit.id, href: "#", children: []})
        } else if (unit.parentChapterNumber === 2) {
          newTOC[newTOC.length - 1].children[newTOC[newTOC.length - 1].children.length - 1].children.push({name: unit.title, id: unit.id, href: "#"})
        }
      }
    })

    setToc(newTOC)
  }

  function goToPage(pageId) {
    const newPage = ebook.units.findIndex(unit => unit.id === pageId)
    if (newPage > -1) {
      $("#ereaderTOC").hide()
      changePage(newPage, true)
    }
  }

  function changePage(pageNumber, logEvent, bookmark) {
    $('#bookmarkBar').css({
      display: 'none'
    });
    let uuid = ebook.units[pageNumber].id
    const url = new URL(window.location)
    url.searchParams.set('chapterUUID', uuid)
    window.history.pushState(null, '', url.toString())

    setChapter(pageNumber)
    localStorage.setItem(localStorageKey, pageNumber)
    if (logEvent) {
      if (bookmark) {
        logActivity('bookmarkClicked', props.contentful_id, pageNumber, null, null, uuid)
      } else {
        logActivity('pageTurned', props.contentful_id, pageNumber, null, null, uuid)
      }
    }
  }

  function quizClick(e) {
    if ($(e.target).hasClass("multiple-choice-quiz-choices")) {
      submitEbookQuizAnswer(e.target)
    } else if ($(e.target).hasClass("ebook-quiz-submit") ) {
      submitEbookSurveyAnswer(e.target)    
    }
  }

  function createAnnotation(color) {
    let entireParagraph
    if (range.startContainer.parentNode.nodeName === 'BODY') {
      entireParagraph = range.startContainer.innerText;
    } else {
      entireParagraph = range.startContainer.parentNode.innerText
    }
    saveAnnotation(ebook.externalId, chapter, ebook.units[chapter].id , color, range.startOffset, range.endOffset, activeHighlight, range.startContainer, range.endContainer, entireParagraph, null, false)
  }

  function deleteAnnotation() {
    destroyAnnotation(annotationId)
    $(`.highlighted-container[data-id='${annotationId}']`).removeClass("highlighted-container").addClass('deleted-annotation')
    $(`.annotation-bookmark[data-id='${annotationId}']`).remove()
    $(`.annotation-note[data-id='${annotationId}']`).remove()
  }

  function createBookmark() {
    let entireParagraph
    if (range.startContainer.parentNode.nodeName === 'BODY') {
      entireParagraph = range.startContainer.innerText;
    } else {
      entireParagraph = range.startContainer.parentNode.innerText
    }
    
    saveAnnotation(ebook.externalId, chapter, ebook.units[chapter].id , 'yellow', range.startOffset, range.endOffset, activeHighlight, range.startContainer, range.endContainer, entireParagraph, null, true)
      .then((annotation) => {
        setBookmarks([...bookmarks, annotation])
      }
    )
    
  }

  function createNote(note) {
    let entireParagraph
    if (range.startContainer.parentNode.nodeName === 'BODY') {
      entireParagraph = range.startContainer.innerText;
    } else {
      entireParagraph = range.startContainer.parentNode.innerText
    }

    saveAnnotation(ebook.externalId, chapter, ebook.units[chapter].id , 'yellow', range.startOffset, range.endOffset, activeHighlight, range.startContainer, range.endContainer, entireParagraph, note, false)
      .then((annotation) => {
        setNotes([...bookmarks, annotation])
      }
    )
  }

  async function getBookmarks(ebookId) {
    let bookmarks = await fetch(`${window.location.origin}/annotations/bookmarks/${ebookId}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({
      //     ebook_contentful_id: ebookId,
      //     page: page
      // })
    }).then(response => response.json())
      .then(obj => {
        return (obj.bookmarks)
      })

      setBookmarks(bookmarks.filter(bookmark => bookmark.bookmark))
      setNotes(bookmarks.filter(note => !!note.notes))
    
  }

  function addNote() {
    $('#notesTool').css({
      display: 'block'
    });
  }


  function toggleLanguagePicker() {
    setDisplayTranslations(!displayTranslations);
  }

  function toggleFontPicker() {
    setDisplayFontPicker(!displayFontPicker)
  }

  function fontUp() {
    if (fontSize < upperFontLimit) {
      setFontSize(fontSize + 1)
    }
  }

  function fontDown() {
    if (fontSize > lowerFontLimit) {
      setFontSize(fontSize - 1)
    }
  }

  function checkClick(e) {
    e.stopPropagation()
    $('#notificationPopUp').css({
      display: 'none'
    });
    setDisplayTranslations(null);
    
    // check to see if user clicked on a quiz
    if (e.target && (e.target.closest(".ebook-quiz") !== null || e.target.closest(".ebook-short-answer-quiz"))) {
      quizClick(e)
    }
    
    //Close annotation tools if click is outside annotation tools
    if (e.target && (e.target.closest("#annotationsTools") === null)) {
      $('#annotationsTools').css({
        display: 'none'
      });
    }
    
    //open annotation tools if highlight is clicked on
    if (e.target && (e.target.closest(".highlighted-container"))) {
      $('#annotationsTools').css({
        display: 'block',
        position: 'absolute',
        top: e.clientY,
        left: e.clientX
      });
      setAnnotationId(e.target.dataset.id)
    }

    if (e.target && (e.target.closest("a"))) {
      
      logActivity("linkClicked", props.contentful_id, chapter, nil, nil, ebook.units[chapter].id)
    }
    
    setMousePositions(e)
  
  }



  function setMousePositions(e) {
    setPositionX(e.clientX)
    setPositionY(e.clientY)
    if (document.getSelection().toString().length > 0) {
      $('#annotationsTools').css({
        display: 'block',
        position: 'fixed',
        top: e.clientY,
        left: e.clientX
      });
      let selection = document.getSelection()
      setActiveHighlight(selection.toString())
      setRange(selection.getRangeAt(0))
      setSelection(selection)
      setAnnotationId(null)
    }
  }

  function initiatePoll(quizuuid) {
    // TODO update this to page id if using in an ebook
    if (chapter == 'bellRinger' || chapter == 'miniLesson') {
      const response = createPoll(quizuuid, props.contentful_id, chapter, chapter)
      response.then((poll) =>{
        console.log(poll)
        //Add Poll
        if (poll.id) {
          setPollId(poll.id)
        }
      })
    } else {
      const response = createPoll(quizuuid, props.contentful_id, 'ebook')
      response.then((poll) =>{
        console.log(poll)
        //Add Poll
        if (poll.id) {
          setPollId(poll.id)
        }
      })
    }
   
  }

  return (
    <div>
        <nav className="container-fluid ebookNav">
            <div className="row">
                { toc.length > 0 &&
                    <TableOfContents navigation={toc} goToPage={goToPage} title={ebook.title} econgame={ebook.externalId === "6iCpQYW3WKvGUAzma9mh5X" || ebook.externalId === "2dRen2zWNUARLxLc0xqcJH"} />
                }
                { props.accountLogo && 
                  <img className="poptential-logo" src={props.accountLogo} alt="Poptential Logo" />
                }

                { !props.accountLogo && 
                  <img className="poptential-logo" src="/poptential/poptential-logo.png" alt="Poptential Logo" />
                }
                
                  { props.bellRinger ?
  
                    <a className="nav-close-btn" data-toggle="tooltip" data-placement="bottom" title="Close" href="/bell-ringers">
                        <span className="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    </a>
                    :
                    <a className="nav-close-btn" data-toggle="tooltip" data-placement="bottom" title="Close" href="/">
                      <span className="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    </a>
  
                  }

                <div className="action-btn-group hidden-xs">
                  <div className="dropdown-font-menu" aria-labelledby="dropdownMenuButton" style={{display: displayFontPicker ? 'block' : 'none'}}>
                      <label className="font-size-selector">Font Size</label>
                      <div className='font-size-buttons'>
                        <a href="#" className='font-down-button font-button' onClick={fontDown}>-</a>
                        <a href="#" className='font-up-button font-button' onClick={fontUp}>+</a>
                      </div>
                    </div>
                    <div className="btn btn-primary dropdown-toggle btn-circle btn-lg" id="dont-size-button" onClick={toggleFontPicker}>
                      <img className="font-logo" src="/poptential/TextSmallerInverted.png" alt="font size button" style={{transform: "translate(-7px, -2px)", width: "28px", height: "auto"}} />
                    </div>
                    
                  { props.canPDF && !(props.bellRinger || props.miniLesson) && chapterId && 
                    <>
                      <a href={"/pdf-export/" + props.contentful_id + "/" + chapterId + ".pdf"} className="btn btn-primary dropdown-toggle btn-circle btn-lg">
                          <div style={{transform: "translate(-7px, 2px)"}}>
                            PDF
                          </div>
                      </a>
                    </>
                  }

                  {/* Language Selector */}
                  { translations.length > 1 &&
                    <>
                      <button onClick={() => toggleLanguagePicker()} type="button" className="btn btn-primary dropdown-toggle btn-circle btn-lg" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Language">
                        <span className="glyphicon glyphicon-globe notification-icon" aria-hidden="true">
                        </span>
                      </button>
                      <div className="dropdown-lang-menu" style={{display: displayTranslations ? "block" : "none"}}>
                        {translations.map((translation) => (
                          <>
                            <a className="dropdown-item language-picker" key={Object.keys(translation)[0]} onClick={() => setLocale(Object.keys(translation)[0])} style={{padding: "10px", fontSize: "1.5rem"}} >{Object.values(translation)[0]}</a>
                            <br></br>
                          </>
                        ))}
                      </div>
                    </>
                  } 
                  {/* Bookmarks bar */}
                  { !props.miniLesson && !props.bellRinger &&
                    <>
                      <button onClick={() => $('#bookmarkBar').toggle()} type="button" className="btn btn-primary dropdown-toggle btn-circle btn-lg  nav-bookmark-btn" id="bookmarkDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Language">
                        <span className="glyphicon glyphicon-bookmark notification-icon" aria-hidden="true">
                        </span>
                      </button>
                      <button onClick={() => $('#notesBar').toggle()} type="button" className="btn btn-primary dropdown-toggle btn-circle btn-lg  nav-bookmark-btn" id="bookmarkDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Language">
                        <span className="glyphicon glyphicon-file notification-icon" aria-hidden="true">
                        </span>
                      </button>
                    </>
                  } 
                </div>
            </div>
        </nav>
        <div className="flex flex-row justify-center items-center w-full loading" id="ereader" style={{height: "90vh"}}>

          {/* SMALL TODO LIST */}
          {/* quotes image */}
          {/* Icons */}
          {/* Arrow spacing */}

          { chapter > 0 &&
            <div onClick={decrementChapter} id="prevArrow" className="arrow ebook-arrows">‹</div>
          }
          <main className="flex flex-row justify-center items-center w-full" style={{height: "100%", paddingBottom: "100px"}}>
            <div id="epub_container" className="flex flex-row justify-center items-center w-full h-full">
              <div className="ebook-loader"></div>  
              <div id="myFrame" className="flex flex-row justify-center items-center border"  frameBorder="0" style={{paddingRight: "0px", paddingBottom: "0px", width: "86%", height: "90%"}}>
                <AnnotationWidget createAnnotation={createAnnotation} deleteAnnotation={deleteAnnotation} createBookmark={createBookmark} addNote={addNote}/>
                <div class={'font-size-' + fontSize} id="frameBody" onClick={checkClick} >
                </div>
              </div>
              <div className="modal-loading"></div>
            </div>
          </main>
          
          { moreChapters() && 
            <div onClick={incrementChapter} id="nextArrow" className="arrow ebook-arrows">›</div>
          }
          <NoteWidget createNote={createNote} />
          <BookmarkBar bookmarks={bookmarks} changePage={changePage}/>
          <NotesBar notes={notes} changePage={changePage}/>
          { !props.isStudent &&
            <Poll pollId={pollId} setPollId={setPollId}/>
          }

          <NotificationPopUp message={props.notificationMessage}/>
        </div>
        <div className="mob-content-nav">
          { chapter > 0 &&
            <button type="button" className="btn btn-default btn-md btn-mobile-back first" onClick={decrementChapter}>Back</button>
          }
          { moreChapters() && 
            <button type="button" className="btn btn-primary btn-md btn-mobile-next second" onClick={incrementChapter}>Next</button>
          }
        </div>
    </div>
  );
};

export default Ereader;
